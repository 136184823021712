<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2"><u>Determination of stock BSA concentration by the Bradford Method.</u></p>
      <p class="mb-3">Fill in the tables below with your experimental data.</p>

      <table class="mb-3">
        <thead>
          <tr>
            <th>Calibration cuvette #</th>
            <th>
              Abs @
              <stemble-latex content="$466\,\text{nm}$" />
            </th>
            <th>
              Abs @
              <stemble-latex content="$595\,\text{nm}$" />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td style="font-weight: bold">1</td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette1Abs466"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette1Abs595"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">2</td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette2Abs466"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette2Abs595"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">3</td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette3Abs466"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette3Abs595"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">4</td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette4Abs466"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette4Abs595"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">5</td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette5Abs466"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette5Abs595"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">6</td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette6Abs466"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette6Abs595"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">7</td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette7Abs466"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.table1Cuvette7Abs595"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table class="mb-3">
        <thead>
          <tr>
            <th>Dilute BSA cuvette #</th>
            <th>
              Abs @
              <stemble-latex content="$466\,\text{nm}$" />
            </th>
            <th>
              Abs @
              <stemble-latex content="$595\,\text{nm}$" />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td style="font-weight: bold">1</td>
            <td>
              <calculation-input
                v-model="inputs.table2Cuvette1Abs466"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.table2Cuvette1Abs595"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">2</td>
            <td>
              <calculation-input
                v-model="inputs.table2Cuvette2Abs466"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.table2Cuvette2Abs595"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">3</td>
            <td>
              <calculation-input
                v-model="inputs.table3Cuvette3Abs466"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.table3Cuvette3Abs595"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <p class="mb-2">
        Absorbance of BSA solution at
        <stemble-latex content="$280\,\text{nm}$:" />
      </p>
      <s-textarea-short
        v-model="inputs.abs280"
        class="mb-3"
        outlined
        :counter="true"
        maxlength="10"
      />
    </v-form>
  </div>
</template>

<script>
import STextareaShort from '@/common/components/STextareaShort.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput.vue';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'BiochemUManEx4DataPartA',
  components: {
    StembleLatex,
    CalculationInput,
    STextareaShort,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        table1Cuvette1Abs466: null,
        table1Cuvette1Abs595: null,
        table1Cuvette2Abs466: null,
        table1Cuvette2Abs595: null,
        table1Cuvette3Abs466: null,
        table1Cuvette3Abs595: null,
        table1Cuvette4Abs466: null,
        table1Cuvette4Abs595: null,
        table1Cuvette5Abs466: null,
        table1Cuvette5Abs595: null,
        table1Cuvette6Abs466: null,
        table1Cuvette6Abs595: null,
        table1Cuvette7Abs466: null,
        table1Cuvette7Abs595: null,
        table2Cuvette1Abs466: null,
        table2Cuvette1Abs595: null,
        table2Cuvette2Abs466: null,
        table2Cuvette2Abs595: null,
        table2Cuvette3Abs466: null,
        table2Cuvette3Abs595: null,
        abs280: null,
      },
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
